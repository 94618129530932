import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data }) => {
  return (
    <Layout>
      <SEO title="Page not found" />
      <p>Apologies&mdash;it doesn&apos;t look like that page exists.</p>
    </Layout>
  )
}
